import { Box, SystemStyleObject } from '@chakra-ui/react';
import { Icons, Link, SvgSpriteConsumer } from '@pluxee-design-system/core';
import md5 from 'blueimp-md5';
import useCurrentLocale from 'data/useCurrentLocale';
import { useMemo } from 'react';
import { Language } from 'types/config';

interface LocationLocatorProps {
  masterId?: string | null;
  size?: 'small' | 'default';
  sx?: SystemStyleObject;
  text?: string;
  variant: 'table' | 'link';
}

const locatorUrls: Record<Language, string> = {
  en: 'https://vyhledavac.pluxee.cz/en/partner-place-details?q=',
  cs: 'https://vyhledavac.pluxee.cz/cs/detail-provozovny?q=',
};

const LocationLocator = ({ masterId, size, sx, text, variant }: LocationLocatorProps) => {
  const isSmall = size === 'small';
  const { value: currentLocale } = useCurrentLocale();
  const countryCode = useMemo<Language>(
    () => currentLocale.split('-')?.[0]?.toLowerCase() ?? currentLocale,
    [currentLocale],
  );
  const md5Hash = useMemo(() => masterId && md5(masterId.replaceAll('-', ',')), [masterId]);
  const locatorUrl = locatorUrls[countryCode] && `${locatorUrls[countryCode]}${md5Hash}`;

  if (variant === 'table') {
    if (!md5Hash || !locatorUrl) return <>&mdash;</>;

    return (
      <Link href={locatorUrl} variant="primary" isExternal>
        <SvgSpriteConsumer size={24} svgId={Icons.STORE24} />
      </Link>
    );
  }

  if (!md5Hash || !locatorUrl) return null;

  return (
    <Box sx={isSmall ? { 'a > div': { marginRight: 0 }, ...sx } : sx}>
      <Link
        leftIcon={
          <SvgSpriteConsumer
            size={isSmall ? 16 : 24}
            svgId={isSmall ? Icons.STORE16 : Icons.STORE24}
          />
        }
        size={size}
        href={locatorUrl}
        isExternal
      >
        {text}
      </Link>
    </Box>
  );
};

export default LocationLocator;
