import EllipseStatus from 'common/feedback/EllipseStatus';
import { ComponentProps } from 'components/VirtualizedTable/types';
import { Employee } from 'generated/models';
import { I18nProps, withTranslations } from 'i18n';

interface ResponsibilityCellProps extends ComponentProps<Employee>, I18nProps {}

const ResponsibilityCell = ({
  row: { contactPerson, responsibilities },
  t,
}: ResponsibilityCellProps) => (
  <EllipseStatus variant={contactPerson ? 'shipped' : 'expired'}>
    {responsibilities?.[0] ? (
      t(
        `responsibilities.responsibilities.${responsibilities?.[0]?.nameKey}`,
        responsibilities?.[0]?.name,
      )
    ) : (
      <>&mdash;</>
    )}
  </EllipseStatus>
);

export default withTranslations(ResponsibilityCell);
