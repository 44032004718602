import { Text } from '@chakra-ui/react';
import { Location, LocationEmployeeItem } from 'generated/models';
import { ComponentProps } from 'components/VirtualizedTable/types';

const AddressCell = ({ row }: ComponentProps<Location & LocationEmployeeItem>) => {
  const address = row?.address ?? row?.location?.address;
  return (
    <Text variant="body.mediumBold" noOfLines={2}>
      {address?.street} {address?.houseNumber}
    </Text>
  );
};

export default AddressCell;
