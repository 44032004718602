import { useCompanyPositions } from 'api/companyApi';
import { useDepartments, useResponsibilities, useUserRoles } from 'api/defaultApi';
import SelectControl, { SelectControlProps } from 'common/forms/SelectControl';
import {
  CompanyDepartment,
  CompanyPosition,
  CompanyPositionsResponse,
  DepartmentListResponse,
  Responsibility,
  ResponsibilityListResponse,
  UserRole,
  UserRoleEnum,
  UserRoleListResponse,
} from 'generated/models';
import withData from 'HOC/withData';

export const PositionSelect = withData<
  CompanyPositionsResponse,
  CompanyPosition[],
  any,
  Omit<SelectControlProps, 'options'>
>(SelectControl, {
  namespace: 'company_positions',
  propsKey: 'options',
  selector: (response, t) =>
    response.entries.map((role) => ({
      value: role.id,
      label: t(`company_positions.company_positions.${role.nameKey}`, role.name),
    })),
  defaultData: [],
  useFetcher: useCompanyPositions,
});

export const ResponsibilitySelect = withData<
  ResponsibilityListResponse,
  Responsibility[],
  any,
  Omit<SelectControlProps, 'options'>
>(SelectControl, {
  namespace: 'responsibilities',
  propsKey: 'options',
  selector: (response, t) =>
    response.entries.map((e) => ({
      value: e.id,
      label: t(`responsibilities.responsibilities.${e.nameKey}`, e.name),
    })),
  useFetcher: useResponsibilities,
});

export const DepartmentSelect = withData<
  DepartmentListResponse,
  CompanyDepartment[],
  any,
  Omit<SelectControlProps, 'options'>
>(SelectControl, {
  namespace: 'departments',
  propsKey: 'options',
  selector: (response, t) =>
    response.entries.map((e) => ({
      value: e.id,
      label: t(`departments.departments.${e.nameKey}`, e.name),
    })),
  useFetcher: useDepartments,
});

export const AccessRoleSelect = (contractId?: string, filterRole?: UserRoleEnum) =>
  withData<UserRoleListResponse, UserRole[], any, Omit<SelectControlProps, 'options'>>(
    SelectControl,
    {
      namespace: 'ngm_access_roles',
      propsKey: 'options',
      selector: (response, t) =>
        (response?.entries || [])
          .filter((e) => (filterRole ? e.code === filterRole || e.enabled : e.enabled))
          .map((e) => ({
            value: e.code,
            label: t(`ngm_access_roles.ngmAccessRoles.${e.code}`, e.name),
            text: t(`ngm_access_roles.ngmAccessRolesDesciption.${e.code}`, e.name),
          })),
      useFetcher: () => useUserRoles(contractId),
    },
  );
