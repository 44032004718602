import { Box, Flex, useMultiStyleConfig } from '@chakra-ui/react';
import { ReactNode } from 'react';

export enum EllipseStatusVariant {
  CREATED = 'created',
  INPROGRESS = 'inProgress',
  DELAY = 'delay',
  SHIPPED = 'shipped',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
}

interface EllipseStatusProps {
  children?: ReactNode;
  // same as StatusVariant
  title?: string;
  variant?: `${EllipseStatusVariant}`;
}

const EllipseStatus = ({ children, title, variant }: EllipseStatusProps) => {
  const styles = useMultiStyleConfig('TagStatus', { variant });

  return (
    <Flex justify="space-between" align="center" gap={2} title={title}>
      <Box
        sx={{ ...styles.ellipse, width: 4, height: 4 }}
        flexShrink={0}
        flexGrow={0}
        flexBasis={4}
      />
      {children}
    </Flex>
  );
};

export default EllipseStatus;
