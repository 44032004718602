import { Employee } from 'generated/models';
import { I18nProps, withTranslations } from 'i18n';
import { ComponentProps } from 'components/VirtualizedTable/types';

interface CompanyPositionCellProps extends ComponentProps<Employee>, I18nProps {}

const CompanyPositionCell = ({ row: { companyPosition }, t }: CompanyPositionCellProps) => (
  <>
    {companyPosition ? (
      t(`company_positions.companyPositions.${companyPosition.nameKey}`, companyPosition.name)
    ) : (
      <>&mdash;</>
    )}
  </>
);

export default withTranslations(CompanyPositionCell);
