import { TagStatus } from '@pluxee-design-system/core';
import { LocationStatusEnum } from 'generated/models';
import { I18nProps, withTranslations } from 'i18n';

interface LocationStatusProps extends I18nProps {
  status?: LocationStatusEnum;
}

export const getLocationStatus = (status?: LocationStatusEnum) => {
  switch (status) {
    case LocationStatusEnum.Activating:
    case LocationStatusEnum.Creating:
    case LocationStatusEnum.Deactivating:
    case LocationStatusEnum.Deleting:
    case LocationStatusEnum.New:
    case LocationStatusEnum.Updating:
      return {
        variant: 'inProgress',
        tKey: 'global_texts.statuses.pending',
        tDefault: 'Pending',
      };
    case LocationStatusEnum.Active:
      return {
        variant: 'shipped',
        tKey: 'global_texts.statuses.active',
        tDefault: 'Active',
      };
    case LocationStatusEnum.Inactive:
      return {
        variant: 'expired',
        tKey: 'global_texts.statuses.inactive',
        tDefault: 'Inactive',
      };
    case LocationStatusEnum.Failed:
    default:
      return {
        variant: 'canceled',
        tKey: 'global_texts.statuses.failed',
        tDefault: 'Failed',
      };
  }
};

// TODO: create one component?
const LocationStatus = ({ status, t }: LocationStatusProps) => {
  if (!status) {
    return null;
  }

  const { tDefault, tKey, variant } = getLocationStatus(status);
  return (
    <TagStatus
      ellipse
      text={t(tKey, tDefault)}
      // @ts-ignore
      variant={variant}
    />
  );
};

export default withTranslations(LocationStatus);
