import EllipseStatus from 'common/feedback/EllipseStatus';
import { LocationEmployeeItem } from 'generated/models';
import { ComponentProps } from 'components/VirtualizedTable/types';
import { getLocationStatus } from 'components/Locations/LocationStatus';
import { I18nProps, withTranslations } from 'i18n';

interface LocationStatusNameCellProps extends ComponentProps<LocationEmployeeItem>, I18nProps {}

const LocationStatusNameCell = ({ row: { location }, t }: LocationStatusNameCellProps) => {
  const { variant, tKey, tDefault } = getLocationStatus(location.status);
  return (
    // @ts-ignore
    <EllipseStatus title={t(tKey, tDefault)} variant={variant}>
      {location.name}
    </EllipseStatus>
  );
};

export default withTranslations(LocationStatusNameCell);
