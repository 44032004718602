import { Text, TextProps } from '@chakra-ui/react';

interface MerchantIdProps extends Omit<TextProps, 'id'> {
  id?: string | null;
  showEmpty?: boolean;
}

const MerchantId = ({ id, showEmpty = false, ...rest }: MerchantIdProps) =>
  id || showEmpty ? (
    <Text {...rest} noOfLines={1} color="semantic.text.tertiary">
      {id ? `(${id})` : ' '}
    </Text>
  ) : null;

export default MerchantId;
