import { TagStatus } from '@pluxee-design-system/core';
import { EmployeeStatusEnum } from 'generated/models';
import { I18nProps, withTranslations } from 'i18n';

interface EmployeeStatusProps extends I18nProps {
  status?: EmployeeStatusEnum;
}

export const getEmployeeStatus = (status?: EmployeeStatusEnum) => {
  switch (status) {
    case EmployeeStatusEnum.Activating:
    case EmployeeStatusEnum.Creating:
    case EmployeeStatusEnum.Deactivating:
    case EmployeeStatusEnum.Deleting:
    case EmployeeStatusEnum.New:
    case EmployeeStatusEnum.Updating:
      return {
        variant: 'inProgress',
        tKey: 'global_texts.statuses.pending',
        tDefault: 'Pending',
      };
    case EmployeeStatusEnum.Active:
      return {
        variant: 'shipped',
        tKey: 'global_texts.statuses.active',
        tDefault: 'Active',
      };
    case EmployeeStatusEnum.Inactive:
      return {
        variant: 'expired',
        tKey: 'global_texts.statuses.inactive',
        tDefault: 'Inactive',
      };
    case EmployeeStatusEnum.Failed:
    default:
      return {
        variant: 'canceled',
        tKey: 'global_texts.statuses.failed',
        tDefault: 'Failed',
      };
  }
};

// TODO: refactor
const EmployeeStatus = ({ status, t }: EmployeeStatusProps) => {
  if (!status) {
    return null;
  }

  const { tDefault, tKey, variant } = getEmployeeStatus(status);
  return (
    <TagStatus
      ellipse
      text={t(tKey, tDefault)}
      // @ts-ignore
      variant={variant}
    />
  );
};

export default withTranslations(EmployeeStatus);
