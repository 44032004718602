import EllipseStatus from 'common/feedback/EllipseStatus';
import { Employee } from 'generated/models';
import { I18nProps, withTranslations } from 'i18n';
import { ComponentProps } from 'components/VirtualizedTable/types';
import { getPortalAccessVariant } from 'components/Employees/utils';

interface UserRoleCellProps extends ComponentProps<Employee>, I18nProps {}

const UserRoleCell = ({ row: { portalAccessStatus, role }, t }: UserRoleCellProps) => (
  <EllipseStatus variant={getPortalAccessVariant(portalAccessStatus)}>
    {role ? t(`ngm_access_roles.ngmAccessRoles.${role}`, role) : <>&mdash;</>}
  </EllipseStatus>
);

export default withTranslations(UserRoleCell);
