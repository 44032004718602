import { Location, LocationEmployeeItem, LocationItem, LocationSoft } from 'generated/models';
import { ComponentProps } from 'components/VirtualizedTable/types';
import MerchantId from 'components/MerchantId';
import React from 'react';

const MasterIdCell = ({
  row,
}: ComponentProps<Location & LocationEmployeeItem & LocationItem & LocationSoft>) => (
  <>
    {row?.masterId ?? row?.location?.masterId}
    <MerchantId
      variant="body.mediumBold"
      id={row?.merchantLocationId ?? row?.location?.merchantLocationId}
    />
  </>
);

export default MasterIdCell;
